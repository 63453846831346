.navItem {
  text-decoration: none;
  color: #676767;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.navItem > img {
  height: 40px;
}
.navItem > span {
  font-size: 18px;
}
.navItem > .light {
  color: #676767;
}
.navItem > .dark {
  color: #000;
}
.navItem > .active {
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .navItem > span {
    display: none;
  }
}
