.layout {
  display: grid;
  height: 100vh;
  grid-template-columns: 200px auto;
}

@media only screen and (max-width: 768px) {
  .layout {
    grid-template-columns: 60px auto;
  }
}
