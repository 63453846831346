.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.account::-webkit-scrollbar {
  display: none;
}

.account > .displayName {
  font-size: 24px;
  font-weight: 600;
}
.account > .username {
  color: #676767;
  font-weight: 500;
  font-size: 18px;
}

.account > .metrics {
  margin-top: 5px;
  display: flex;
  gap: 50px;
  text-align: center;
  font-weight: 500;
}
