@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}
html,
body {
  height: 100vh;
}
body {
  background: rgb(229, 246, 64);
  background: linear-gradient(90deg, rgba(229, 246, 64, 1) 0%, rgba(255, 157, 0, 1) 100%);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
